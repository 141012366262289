import { getPreparedLogbookData } from "./logbook-data-prep";
import { getPreparedMarketplaceData } from "./marketplace-data-prep";
import { getUsersPreparedData } from "./users-data-prep";

export const prepareUsersData = (usersData) => {
  return getUsersPreparedData(usersData)
}

export const prepareHomesData = (homesData) => {
    return getPreparedLogbookData(homesData)
}

export const prepareUsersMpData = (mpUsersData) => {
  return getPreparedMarketplaceData(mpUsersData)
}