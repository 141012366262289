import * as React from "react"
import { useState, useEffect } from 'react';
import DataDisplayComponent from "../components/data-display";
import { getAllHomesData, getAllUsersData } from "../services/admin-apis";
import { toggleInfoPopup } from "../components/postcode-data-overlay";
import UserDataCharts from "../components/user-data-charts";
import LogbookDataCharts from "../components/logbook-data-charts";
import { prepareHomesData, prepareUsersData, prepareUsersMpData } from "../services/admin-panel/data-aggregator";
import { authCallbackUrl, authClientId, backAuthServerUrl, frontClientUrl } from "../services/constant";
import MarketplaceDataCharts from "../components/marketplace-data-charts";
import LoadingTextAnimation from "../components/loader";
import { getAccessToken, logout } from "../services/auth";

const AdminPanel = () => {


  const isBrowser = () => typeof window !== "undefined"
  const logbookRelaseDateTime = "2023-05-06 02:00:00"

  const loginUrl =
    backAuthServerUrl() +
    "/oauth/authorize?response_type=code&client_id=" +
    authClientId() +
    "&redirect_uri=" +
    authCallbackUrl() +
    "&scope=read"

  const [isAuthorized, setIsAuthorized] = useState();
  const [shouldDisplayStat, setShouldDisplayStat] = useState();
  const [postcodeCount, setPostcodeCount] = useState();
  const [displayUserData, setDisplayUserData] = useState(false);
  const [displayHomeData, setDisplayHomeData] = useState(false);
  const [displayMpData, setDisplayMpData] = useState(false);
  const [homesDataOptions, setHomesDataOptions] = useState();
  const [usersDataOptions, setUsersDataOptions] = useState();
  const [mpDataOptions, setMpDataOptions] = useState();
  const [loading, setLoading] = useState(true);
  const [logbookInMaintenance, setLogbookInMaintenance] = useState(false)

;  let usersData = [];
  let homesData = [];
  let marketplaceUsers = [];

  const [dataToDisplay, setDataToDisplay] = useState(undefined);

  function adminLogin () {
    window.localStorage.setItem('stateRedirectURL', frontClientUrl() + '/admin-panel')
    window.location.href = backAuthServerUrl() + "/oauth/sso-logout?callback_uri=" + backAuthServerUrl() + '/login';
    // logout(true)
    // window.location.replace(backAuthServerUrl() + '/login')
        
  }

  const toggleChildComponent = (accordan) => {
    if(accordan == "homes"){
      setDisplayHomeData(!displayHomeData)
      setDisplayUserData(false);
      setDisplayMpData(false);
    }
    
    if(accordan == "users"){
      setDisplayUserData(!displayUserData);
      setDisplayHomeData(false)
      setDisplayMpData(false);
    }

    if(accordan == "marketplace"){
      setDisplayMpData(!displayMpData);
      setDisplayUserData(false);
      setDisplayHomeData(false)
    }  

    if(accordan == "logbook"){
      toggleInfoPopup(true)
    }

  }
  const getHomesDataRecur = async function(page) {
    // const res = await getAllHomesData(page)

    const res = process.env.GATSBY_APP_ENV == 'development' || process.env.GATSBY_APP_ENV == 'staging' ? await getAllHomesData(page) : await getAllHomesData(page, logbookRelaseDateTime)
    // const res = await getAllHomesData(page, logbookRelaseDateTime)
    if(res?.data?.code == 200 && res?.data?.data?.data.length > 0){
      return res?.data?.data?.data.concat(await getHomesDataRecur(page + 1))
    }
    else{
      return res?.data?.data?.data
    }
  };

  const getUsersDataRecur = async function(page) {
    const res = await getAllUsersData(page)

    if(res?.data?.code == 200 && res?.data?.data?.data.length > 0){
      return res?.data?.data?.data.concat(await getUsersDataRecur(page + 1))
    }
    //else{
      return res?.data?.data?.data
    // }
  };

  useEffect(() => {
    setLoading(true)
    const code = getAllHomesData().then(res => {
        if(res?.data?.code == 200){
          (async () => {
            usersData = await getUsersDataRecur(1)
            homesData = await getHomesDataRecur(1)
            //get marketplace users
            fetch(`/.netlify/functions/get-marketplace-users?items_per_page=1000`, {
              method: 'get',
              headers: {'Content-Type': 'application/json'}
          })
              .then(result => result.json())
              .then(result => {
                  marketplaceUsers = result?.users
                  setData()
                  setLoading(false)
              })
              .catch((err) => console.log('err', err));

          }) ();
          setIsAuthorized(true)
        }
        else{
          if(getAccessToken()){
            setIsAuthorized(false)
          }
          else{
            window.localStorage.setItem('stateRedirectURL', frontClientUrl() + '/admin-panel')
            window.location.replace(loginUrl)
          }
          
          setLoading(false)
        }
      }).catch(error => {
        if(getAccessToken()){
          setIsAuthorized(false)
        }
        else{
          window.localStorage.setItem('stateRedirectURL', frontClientUrl() + '/admin-panel')
          window.location.replace(loginUrl)
        }

        setLoading(false)
      })
   
    
    
  },[]);

  function setData(){

    const filteredHomesData = process.env.GATSBY_APP_ENV == 'development' || process.env.GATSBY_APP_ENV == 'staging' ? 
    homesData : 
    homesData.filter(home => {
      return !home.user.email.includes("evitat.com.au") &&
      !home.user.email.includes("test") && 
      !home.user.email.includes("yopmail.com") && 
      !home.user.email.includes("mailinator.com") &&
      !home.user.email.includes("tatvasoft") &&
      !home.user.email.includes("parth.kharecha") &&
      !home.user.email.includes("sanjay.kumar.balyan") &&
      !home.user.email.includes("spendkar@kstack.com.au")
    })

    const filteredUsersData = process.env.GATSBY_APP_ENV == 'development' || process.env.GATSBY_APP_ENV == 'staging' ? 
    usersData : 
    usersData.filter(user => {
      return !user.email.includes("tech+logbookAdmin@evitat.com.au")
    })

    const filteredMpUsersData = process.env.GATSBY_APP_ENV == 'development' || process.env.GATSBY_APP_ENV == 'staging' ? 
    marketplaceUsers : 
    marketplaceUsers.filter(user => {
      return !user.email.includes("sanjay.kumar.balyan") &&
      !user.email.includes("evitat.com.au") &&
      !user.email.includes("parth.kharecha") &&
      !user.email.includes("yopmail.com") && 
      !user.email.includes("mailinator.com") &&
      !user.email.includes("tatvasoft") &&
      !user.email.includes("test")
    })

    const caseyPostcodes = [3912,3980,3805,3156,3807,3977,3975,3806,3802,3177,3804,3978,3976,3803]
    // const caseyPostcodes = "3000"
    
    const caseyHomes = filteredHomesData.filter(home => {
      return caseyPostcodes.toString().includes(home.postcode)
    })

    const preparedUsersDataOptions = prepareUsersData(filteredUsersData)
    setUsersDataOptions(preparedUsersDataOptions)

    const preparedHomesDataOptions = prepareHomesData(filteredHomesData)
    setHomesDataOptions(preparedHomesDataOptions)

    const preparedMpDatOptions = prepareUsersMpData(filteredMpUsersData)
    setMpDataOptions(preparedMpDatOptions)

    const homePostcodes = []

    filteredHomesData.forEach(home => {
      const data = {
        postcode: home.postcode,
        user_id: home.user_id,
      }
      homePostcodes.push(data)
    })
  
    setPostcodeCount(
      Array.from(new Set(filteredHomesData)).map(home =>(
      // ({userInfo: filteredUsersData.filter(userData => userData.id === home.user_id).at(0), houseName: home.name, postcode: home.postcode, count: homePostcodes.filter(pc => pc.postcode === home.postcode && home.user_id === pc.user_id).length})))
      ({userInfo: filteredUsersData.filter(userData => userData.id === home.user_id).at(0), postcode: home.postcode})))
    )
   
    setDataToDisplay([
      { 
        name: 'Total users', 
        stat: filteredUsersData.length,
        id: 'users'
      },
      { 
        name: 'Total eLogbook homes created', 
        stat: filteredHomesData.length,
        id: 'homes'
      },
      { 
        name: 'Total marketplace users', 
        stat: filteredMpUsersData.length,
        id: 'marketplace'
      },
    ])
    
    setShouldDisplayStat(true)
    
  }
  
  return (
    <div>
      { loading &&
          <LoadingTextAnimation loadingText={"Fetching data..."} />
      }
      {shouldDisplayStat === true && isAuthorized === true &&
        <>
          <DataDisplayComponent dataToDisplay={dataToDisplay} postcodeData={postcodeCount} toggleChildComponent={toggleChildComponent}/>
          
          {displayUserData && 
            usersDataOptions.map((userDataToDisplay, index) => {
              return <UserDataCharts key={index} data={userDataToDisplay.dataToDisplay} options={userDataToDisplay}/>
            })
          }
          
          { 

            (displayHomeData && logbookInMaintenance) &&
            <div className="overflow-auto">
                <div key="key1331" className="text-center mb-16">
                <span className="text-2xl text-bold ">
                  SCHEDULED MAINTENANCE
                </span>
                <span className="italic"><br></br>
                  We are updating the graphs for logbook data!
                </span>
                </div>
            </div>
          }
          {
            (displayHomeData && !logbookInMaintenance) &&
              homesDataOptions.map(homeDataToDisplay => {
                return <LogbookDataCharts className="overflow-auto" data={homeDataToDisplay.dataToDisplay} options={homeDataToDisplay}/>
              })            
          }

          {displayMpData && 
            mpDataOptions.map(mpDataToDisplay => {
              return <MarketplaceDataCharts className="overflow-auto" data={mpDataToDisplay.dataToDisplay} options={mpDataToDisplay}/>
            })
          }
          
        </>
      }
      {isAuthorized === false && 
        <div className="overflow-hidden lg:relative">
          <div className="grid place-items-center content-center pt-4 sm:pt-4 lg:py-4 bg-white ">
            <span className="mt-1 text-3xl font-bold tracking-tight text-gray-900">401 UNAUTHORIZED</span>
            <span className="mt-1 text-sm tracking-tight text-gray-900">You are not authorized to access this page</span>
            <button className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-500 hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400" onClick={() => adminLogin()} >Log in using correct credentials</button>
          </div>
        </div>
      }
      </div>
    )
}

export default AdminPanel;