import * as React from "react"
import Chart from "react-google-charts"

export default function MarketplaceDataCharts({data, options}) {
    return (
        <div className="overflow-auto">
            <div key={options.customTitle} className="text-center mb-8">
                    <span className="text-2xl text-gray-900 tracking-tight mb-2 sm:text-4xl text-center">{options?.customTitle}</span>
                    <Chart
                        
                        chartType={options?.graphType}
                        width="100%"
                        height="400px"
                        data={data}
                        options={options} 
                    />
            </div>
        </div>
    )

}