import * as React from "react"
import Chart from "react-google-charts"

export default function UserDataCharts({data, options}) {
    return (
        <div className="overflow-auto">
            <div key={options.customTitle} className="text-center mb-8">
                    <span className="text-2xl text-gray-900 tracking-tight mb-2 sm:text-4xl text-center">{options?.customTitle}</span>
                    <Chart
                        className={options?.graphType != 'Table' ? "" : "mt-4"}
                        chartType={options?.graphType}
                        width="100%"
                        height="400px"
                        data={data}
                        options={options} 
                    />
            </div>
        </div>

            // {
            //     userDataList.map((userData, index) => {
            //         return <div key={index} className="text-center mb-16">
            //             <span className="text-4xl font-extrabold text-gray-900 tracking-tight mb-2 sm:text-4xl text-center">{userDataOptionsList[index]?.customTitle}</span>
            //             <Chart
            //             key={index}
            //             chartType="AreaChart"
            //             width="100%"
            //             height="400px"
            //             data={userData}
            //             options={userDataOptionsList[index]}
            //         /></div>
            //     })
            // }
        
    )

}