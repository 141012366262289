import { Fragment, useEffect, useState } from "react"
import React from "react"
import { Dialog, Transition } from "@headlessui/react"
import parse from "html-react-parser"
// import { CheckIcon } from '@heroicons/react/24/outline'

let toggleInfoPopup = () => {}
const people = [
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    // More people...
  ]
  
const InfoPopupComponent = ({postcodeData}) => {
  const [open, setOpen] = useState(false)
  const [titleText, setTitleText] = useState()
  const [infoText, setInfoText] = useState()

  toggleInfoPopup = (showVal) => {
    // setContent(htmlContent)
    setOpen(showVal)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6 sm:w-full sm:max-w-sm">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    {/* <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Renovators and their property postcodes
                    </Dialog.Title>
                    <div className="mt-2 content-center">

                    <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                
                            { postcodeData.length > 0 && 
                                <table className="min-w-full divide-y divide-gray-300 text-center border">
                                    <thead>
                                        <tr className="bg-gray-200">
                                        <th scope="col" className="px-3 py-3.5 text-left text-base text-center font-semibold text-gray-900">
                                            Name with email
                                        </th>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-center text-base font-semibold text-gray-900 sm:pl-0">
                                            Postcode
                                        </th>
                                        {/* <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-center text-base font-semibold text-gray-900 sm:pl-0">
                                            House name
                                        </th> */}
                                        {/* <th scope="col" className="px-3 py-3.5 text-left text-center text-base font-semibold text-gray-900">
                                            Number of homes
                                        </th> */}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {postcodeData.length > 0 && postcodeData.map((pc) => (
                                        <tr key={pc.postcode}>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                              {pc.userInfo && <a className="text-green-600 hover:text-green-500" href = {"mailto:" + pc.userInfo.email}>{pc.userInfo.name}</a>}
                                            </td>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                            {pc.postcode}
                                            </td>
                                            {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                            {pc.houseName}
                                            </td>
                                            
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                              {pc.count}
                                            </td> */}
                                        </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                }
                                {postcodeData.length == 0 && 
                                    <p>No data found :(</p>}
                            </div>
                            </div>
                        </div>

                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    Okay
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export { toggleInfoPopup }
export default InfoPopupComponent
