import * as React from "react"
import InfoPopupComponent, { toggleInfoPopup } from "./postcode-data-overlay"

const stats = [
    { name: 'Total users', stat: '513' },
    { name: 'Total eLogbook homes created', stat: '3' },
    { name: 'Total eLogbook homes created in Casey', stat: '0' },
  ]

  export default function DataDisplayComponent({dataToDisplay, postcodeData, toggleChildComponent}) {
    return (
        <div className="overflow-hidden lg:relative">
          <InfoPopupComponent postcodeData={postcodeData}/>
          <div className="grid place-items-center content-center pt-4 sm:pt-4 lg:py-4 bg-white ">
            <h3 className="text-2xl font-semibold leading-6 text-gray-900">Evitat DB statistics</h3>
            <p><i>Click on the accordans to get more information</i></p>
              <dl className={"mt-5 gap-2 grid sm:grid-cols-3 grid-cols-1 grid-flow-row sm:grid-cols-" + dataToDisplay.length}>
                {dataToDisplay.map((item) => (
                    <button key={item.name} className="rounded-full bg-green-500 hover:bg-green-600 overflow-hidden text-center  px-4 py-5 shadow sm:p-6" onClick={() => {toggleChildComponent(item.id)}}>
                        <dt className="truncate text-sm font-medium text-white">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-bold tracking-tight text-white">{item.stat}</dd>
                    </button>
                ))}
              </dl>
              <button onClick={() => {toggleChildComponent("logbook")}}>Click here to reviel logbook users</button>
          </div>
      </div>
    )
  }