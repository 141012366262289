
export const getPreparedLogbookData = (homesData) => {
    const dataArray = []

    // define the chronology of graphs here 
    dataArray.push(getTotalHomesData(homesData))
    dataArray.push(getAveragePercImpactForCategories(homesData))
    dataArray.push(getRenovatorGoalFocusData(homesData))
    dataArray.push(getAverageReductionsBasedOnGoalsData(homesData))
    // dataArray.push(getHomesGasConnectionData(homesData))
    // TODO update gas data showcasing logic
    
    return dataArray.flat(1)
}
const getTotalHomesData = (homesData) => {
    const logbookHomesCountOptions = {
        customTitle: 'Logbook homes',
        graphType: 'AreaChart',
        hAxis: { title: "Number of homes", titleTextStyle: { color: "#333" },},
        vAxis: { minValue: 20 },
        legend: 'none',
        chartArea: { width: "50%", height: "70%" },
        dataToDisplay: undefined //List
    };
      
    const logbookHomesCumulativeOptions = {
        customTitle: 'Logbook homes created (Cumulative)',
        graphType: 'AreaChart',
        hAxis: { title: "Number of homes", titleTextStyle: { color: "#333" },},
        vAxis: { minValue: 20 },
        legend: 'none',
        chartArea: { width: "50%", height: "70%" },
        dataToDisplay: undefined //List
    };

    let homesPerMonthDataToDisplay = [["Month & year", "Homes created"]]
    let cumulatveHomesPerMonthDataToDisplay = [['Month & year', 'Homes created']]

    const sortedHomesData = homesData.sort((h1, h2) => {
        return ( Date.parse(h1.created_at) > Date.parse(h2.created_at) )
      })
    
      sortedHomesData.forEach(home => {
        const createdAt = new Date(home.created_at)
        home.month_year = (createdAt.getMonth() + 1) + "-" + createdAt.getFullYear()
      })
    
      //reducing data
      const totalHomesPerMonth = sortedHomesData.reduce((monthlyData, home) => {
        const monthYear = home.month_year
        if( !monthlyData.hasOwnProperty(monthYear) ){
          monthlyData[monthYear] = 0;
        }
    
        monthlyData[monthYear] = monthlyData[monthYear] + 1
        return monthlyData
      }, {})
  
      let numberOfHomes = 0;

      for (const [key, value] of Object.entries(totalHomesPerMonth)) {
        numberOfHomes += value
        homesPerMonthDataToDisplay.push([key,value])
        cumulatveHomesPerMonthDataToDisplay.push([key, numberOfHomes])
      }

    logbookHomesCountOptions.dataToDisplay = homesPerMonthDataToDisplay
    logbookHomesCumulativeOptions.dataToDisplay = cumulatveHomesPerMonthDataToDisplay

    return [logbookHomesCountOptions, logbookHomesCumulativeOptions]
}

const getAveragePercImpactForCategories = (homesData) => {
    const categoryIdName = {
        1: 'Healthy home',
        2: 'Comfort',
        3: 'Affordability',
        4: 'Water & Energy Efficiency',
        5: 'Social Environmental',
        6: 'Carbon'
    }

    const averageImpactOptions = {
        customTitle: 'Average impact of homes per category',
        graphType: 'BarChart',
        hAxis: { title: "Percentage (%)", titleTextStyle: { color: "#333" }, minValue: 0, maxValue: 20, steps: 5 },
        vAxis: { minValue: 0, maxValue: 20 },
        legend: 'none',
        chartArea: { width: "50%", height: "70%" },
        dataToDisplay: undefined //List
    };

    let avgImpact = {
        'Healthy home': 0, 'Comfort': 0, 'Affordability': 0, 'Water & Energy Efficiency': 0, 'Social Environmental': 0, 'Carbon': 0
    }

    homesData.forEach(homeData => {
        homeData?.categories?.forEach(category => {
          if(categoryIdName[category.category_id] in avgImpact){
            avgImpact[categoryIdName[category.category_id]] += category.impact == null ? 0 : parseInt(category.impact)
          }
          else{
            avgImpact[categoryIdName[category.category_id]] = category.impact == null ? 0 : parseInt(category.impact)
          }
        })
    })

    const avgImpactData = [
        [
            "Category", "Average percentage of impact",  
            { role: "style" },
            {
                sourceColumn: 0,
                role: "annotation",
                type: "string",
                calc: "stringify",
            },
        ]
    ]

    for (const [key, value] of Object.entries(avgImpact)) {
        let color = null
  
        if(key.includes('Health'))
          color = '#306F7E'
        else if(key.includes('Comfort'))
          color = '#4DE184'
        else if(key.includes('Afforda'))
          color = '#64C3AF'
        else if(key.includes('Efficie'))
          color = '#F97415'
        else if(key.includes('Social'))
          color = '#513241'
        else
          color = '#F7E78D'

        avgImpactData.push([key, (value / homesData.length) , color, null])
    }
    averageImpactOptions.dataToDisplay = avgImpactData

    return averageImpactOptions

}

const getRenovatorGoalFocusData = (homesData) => {
    const popularityOfGoalsOptions = {
        customTitle: 'What goals and objectives renovator are focusing on',
        graphType: 'BarChart',
        hAxis: { title: "Percentage (%)", titleTextStyle: { color: "#333" } },
        vAxis: { minValue: 20 },
        legend: 'none',
        chartArea: { width: "50%", height: "70%" },
        dataToDisplay: undefined //List
      };

    let goalCategoryCount = {}

    homesData.forEach(homeData => {
        //goals 
        homeData?.goals?.forEach(goal => {
          if(goal.category.name in goalCategoryCount){
            goalCategoryCount[goal.category.name] += (goal.subcategories.length) 
          }
          else{
            goalCategoryCount[goal.category.name] = (goal.subcategories.length)
          }
        })  
      })
      
    const popularityOfCategoriesData = [
        [
          "Category", "Average percentage of goals selected",  
          { role: "style" },
          {
            sourceColumn: 0,
            role: "annotation",
            type: "string",
            calc: "stringify",
          },
    ]]

    for (const [key, value] of Object.entries(goalCategoryCount)) {
        let color = null
  
        if(key.includes('Health'))
            color = '#306F7E'
          else if(key.includes('Comfort'))
            color = '#4DE184'
          else if(key.includes('Afforda'))
            color = '#64C3AF'
          else if(key.includes('Efficie'))
            color = '#F97415'
          else if(key.includes('Social'))
            color = '#513241'
          else
            color = '#F7E78D'
  
        if(key.includes("Sustai") || key.includes("Social")){
          popularityOfCategoriesData.push(["Social Environmental", (value / ((5 * homesData.length))) * 100 , color, null])
        }
        else if(key.includes("Efficiency")){
          popularityOfCategoriesData.push(["Water & Energy Efficiency", (value / ((5 * homesData.length))) * 100 , color, null])
        }
        else{
          popularityOfCategoriesData.push([key, (value / ((5 * homesData.length))) * 100 , color, null])
        }
      }

    popularityOfGoalsOptions.dataToDisplay = popularityOfCategoriesData

    return popularityOfGoalsOptions
}

const getAverageReductionsBasedOnGoalsData = (homesData) => {
    const avgReductionOptions = {
        graphType: 'BarChart',
        customTitle: 'Based on renovator goals and objectives, how much can they expect to reduce bills and carbon emissions',
        legend: 'none',
        hAxis: { title: "Percentage (%)", titleTextStyle: { color: "#333" } },
        chartArea: { width: "50%", height: "70%" },
        dataToDisplay: undefined //List
    };
    
    let avgReductionsJson = {'Carbon reductions': 0, 'Water bill reductions': 0, 'Energy bills reductions': 0}
    homesData.forEach(homeData => {  
        //reductions data aggregation
        avgReductionsJson['Carbon reductions'] += homeData.carbon_reduction == null ? 0 : parseInt(homeData.carbon_reduction)
        avgReductionsJson['Water bill reductions'] += homeData.water_reduction == null ? 0 : parseInt(homeData.water_reduction)
        avgReductionsJson['Energy bills reductions'] += homeData.energy_reduction == null ? 0 : parseInt(homeData.energy_reduction)
    })

    const avgReductionsData = [
        ['Reductions', 'Average reduction (%)',
        { role: "style" },
        {
            sourceColumn: 0,
            role: "annotation",
            type: "string",
            calc: "stringify",
        },
        ]
    ]

    for (const [key, value] of Object.entries(avgReductionsJson)) {
        let color = null
  
        if(key.includes('Carbon'))
          color = '#014955'
        else if(key.includes('Water'))
          color = '#6DD4C9'
        else
          color = '#F97415'
  
        avgReductionsData.push([key, value / homesData.length, color, null])
    }

    avgReductionOptions.dataToDisplay = avgReductionsData

    return avgReductionOptions
}

const getHomesGasConnectionData = (homesData) => {
    const gasConnectionOptions = {
        graphType: 'PieChart',
        customTitle: 'Gas connection data',
        chartArea: { width: "50%", height: "70%" },
        hAxis: { title: "Month & year", titleTextStyle: { color: "#333" } },
        colors: ['#22C55D', '#808080'],
    };

    let gasConnection = {'Homes not connected to gas': 0, 'Homes connected to gas': 0 }

    homesData.forEach(homeData => {  
        //gas connection data aggregation
        homeData.is_connected_to_gas == "yes" ? gasConnection['Homes connected to gas'] += 1 : gasConnection['Homes not connected to gas'] += 1
    })

    const gasConnectionData = [['Connected', 'Not connected',
        { role: "style" },
        {
            sourceColumn: 0,
            role: "annotation",
            type: "string",
            calc: "stringify",
        }]
    ]

    for (const [key, value] of Object.entries(gasConnection)) {
        let color = null
  
        if(key.includes('not'))
          color = '#22C55D'
        else
          color = '#808080'
  
        gasConnectionData.push([key, value, color, null])
    }

    gasConnectionOptions.dataToDisplay = gasConnectionData

    return gasConnectionOptions
    
}