export const getPreparedMarketplaceData = (mpUsersData) => {
    const dataArray = []

    // define the chronology of graphs here 
    dataArray.push(getTotalMarketplaceUsersData(mpUsersData))
    console.log(dataArray.flat(1))
    return dataArray.flat(1)
}

const getTotalMarketplaceUsersData = (mpUsersData) => {
    const totalUsersMpDataOptions = {
        graphType: 'AreaChart',
        customTitle: "Marketplace user registrations",
        hAxis: { title: "Month & year", titleTextStyle: { color: "#333" } },
        vAxis: { minValue: 0 },
        chartArea: { width: "50%", height: "70%" },
        dataToDisplay: undefined //List
    }; 

    const cumulativeMpUsersDataOptions = {
        graphType: 'AreaChart',
        customTitle: "Cumulative marketplace user registrations",
        hAxis: { title: "Month & year", titleTextStyle: { color: "#333" } },
        vAxis: { minValue: 0 },
        chartArea: { width: "50%", height: "70%" },
        dataToDisplay: undefined //List
    };

    const sortedUserData = mpUsersData.sort((u1, u2) => {
        return u1.timestamp - u2.timestamp
    })

    sortedUserData.forEach(user => {
        const createdAt = new Date(parseInt(user.timestamp) * 1000)
        user.month_year = (createdAt.getMonth() + 1) + "-" + createdAt.getFullYear()
    })

    // reducing data
    const totalUsersPerMonth = sortedUserData.reduce((monthlyData, user) => {
        const monthYear = user.month_year
    
        if( !monthlyData.hasOwnProperty(monthYear) ){
        monthlyData[monthYear] = 0;
        }

        monthlyData[monthYear] = monthlyData[monthYear] + 1
        return monthlyData
    }, {})

    const usersPerMonthDataToDisplay = [["Month & year", "Users registered"]]
    const cumulatveUsersPerMonthDataToDisplay = [["Month & year", "Total users registered"]]
    let numberOfUsers = 0
    for (const [key, value] of Object.entries(totalUsersPerMonth)) {
        numberOfUsers += value
        cumulatveUsersPerMonthDataToDisplay.push([key, numberOfUsers])
        usersPerMonthDataToDisplay.push([key,value])
    }
    
    totalUsersMpDataOptions.dataToDisplay = usersPerMonthDataToDisplay
    cumulativeMpUsersDataOptions.dataToDisplay = cumulatveUsersPerMonthDataToDisplay
    
    return [totalUsersMpDataOptions, cumulativeMpUsersDataOptions]
}