export const getUsersPreparedData = (usersData) => {
    const dataArray = []

    // define the chronology of graphs here 
    dataArray.push(getTotalUsersData(usersData))
    dataArray.push(getUserSegmentationData(usersData))
    dataArray.push(getRenovatorPlanningData(usersData))
    dataArray.push(getProfessionalsSegmentationData(usersData))
    dataArray.push(getB2BUserInfo(usersData))
    return dataArray.flat(1)
}

const getTotalUsersData = (usersData) => {
    const totalUsersDataOptions = {
        graphType: 'AreaChart',
        customTitle: "User registrations",
        hAxis: { title: "Month & year", titleTextStyle: { color: "#333" } },
        vAxis: { minValue: 0 },
        chartArea: { width: "50%", height: "70%" },
        dataToDisplay: undefined //List
    };
    
    const cumulativeUsersDataOptions = {
        graphType: 'AreaChart',
        customTitle: "Cumulative user registrations",
        hAxis: { title: "Month & year", titleTextStyle: { color: "#333" } },
        vAxis: { minValue: 0 },
        chartArea: { width: "50%", height: "70%" },
        dataToDisplay: undefined //List
    };

    const sortedUserData = usersData.sort((u1, u2) => {
        return ( Date.parse(u1.created_at) > Date.parse(u2.created_at) )
      })
    
      sortedUserData.forEach(user => {
        const createdAt = new Date(user.created_at)
        user.month_year = (createdAt.getMonth() + 1) + "-" + createdAt.getFullYear()
      })
    
      //reducing data
      const totalUsersPerMonth = sortedUserData.reduce((monthlyData, user) => {
        const monthYear = user.month_year
        if( !monthlyData.hasOwnProperty(monthYear) ){
          monthlyData[monthYear] = 0;
        }
    
        monthlyData[monthYear] = monthlyData[monthYear] + 1
        return monthlyData
      }, {})
    
      const usersPerMonthDataToDisplay = [["Month & year", "Users registered"]]
      const cumulatveUsersPerMonthDataToDisplay = [["Month & year", "Total users registered"]]

      let numberOfUsers = 0;

      for (const [key, value] of Object.entries(totalUsersPerMonth)) {
        numberOfUsers += value
        cumulatveUsersPerMonthDataToDisplay.push([key, numberOfUsers])
        usersPerMonthDataToDisplay.push([key,value])
      }
      
      totalUsersDataOptions.dataToDisplay = usersPerMonthDataToDisplay
      cumulativeUsersDataOptions.dataToDisplay = cumulatveUsersPerMonthDataToDisplay

      return [totalUsersDataOptions, cumulativeUsersDataOptions]
}

const getRenovatorPlanningData = (usersData) => {
    const renovatorData = {
        customTitle: 'Why Renovators are choosing Evitat',
        graphType: 'BarChart',
        hAxis: { title: "Number of renovators", titleTextStyle: { color: "#333" },},
        vAxis: { minValue: 20 },
        legend: 'none',
        chartArea: { width: "50%", height: "70%" },
        dataToDisplay: undefined //List
    };
    
    const renovationPlanningAndUsers = [["Renovation planning", "Number of users"]]
  
    const countObjReasons = {
      'Looking to renovate in the next 12-24 months': 0,
      'Currently undertaking, or planning a reno in the next 6 months': 0,
      'Browsing': 0,
      'Other': 0
    }
  
    usersData.forEach(user => {
      if(user.role == "RENOVATOR" && user.user_tags != null){
        if(user.user_tags.includes("planning_reno_in_1_2y")){
          countObjReasons['Looking to renovate in the next 12-24 months'] = countObjReasons['Looking to renovate in the next 12-24 months'] + 1
        }
  
        if(user.user_tags.includes("planning_reno_in_6m")){
          countObjReasons['Currently undertaking, or planning a reno in the next 6 months'] = countObjReasons['Currently undertaking, or planning a reno in the next 6 months'] + 1
        }
  
        if(user.user_tags.includes("browsing")){
          countObjReasons['Browsing'] = countObjReasons['Browsing'] + 1
        }
  
        if(user.user_tags.includes("other")){
          countObjReasons['Other'] = countObjReasons['Other'] + 1
        }
  
      }
    })
  
    Object.entries(countObjReasons).map(([key, value]) => [key, value]).forEach(entry => {
      renovationPlanningAndUsers.push(entry)
    })

    renovatorData.dataToDisplay = renovationPlanningAndUsers
    return renovatorData
}

const getUserSegmentationData = (usersData) => {
    const countByUserTypeOptions = {
        customTitle: 'Monthly growth - Type of platform users',
        graphType: 'LineChart',
        hAxis: {title: "Month & Year"},
        vAxis: { title: "Number of users", minValue: 20 },
        chartArea: { width: "50%", height: "70%" },
        dataToDisplay: undefined //List
    };

    const sortedUserData = usersData.sort((u1, u2) => {
      return ( Date.parse(u1.created_at) > Date.parse(u2.created_at) )
    })

    sortedUserData.forEach(user => {
      const createdAt = new Date(user.created_at)
      user.month_year = (createdAt.getMonth() + 1) + "-" + createdAt.getFullYear()
    })

    const countUserUnkown = sortedUserData.reduce((monthlyData, user) => {
      const monthYear = user.month_year
      if( !monthlyData.hasOwnProperty(monthYear) ){
        monthlyData[monthYear] = 0;
      }
      
      if(user.role == "USER" || user.role == null || user.role == undefined){
        monthlyData[monthYear] = monthlyData[monthYear] + 1        
      }
      return monthlyData
    }, {})

    const countUserRenovator = sortedUserData.reduce((monthlyData, user) => {
      const monthYear = user.month_year
      if( !monthlyData.hasOwnProperty(monthYear) ){
        monthlyData[monthYear] = 0;
      }
      
      if(user.role == "RENOVATOR"){
        monthlyData[monthYear] = monthlyData[monthYear] + 1        
      }
      return monthlyData
    }, {})

    const countUserSupplier = sortedUserData.reduce((monthlyData, user) => {
      const monthYear = user.month_year
      if( !monthlyData.hasOwnProperty(monthYear) ){
        monthlyData[monthYear] = 0;
      }
      
      if(user.role == "SUPPLIER"){
        monthlyData[monthYear] = monthlyData[monthYear] + 1        
      }
      return monthlyData
    }, {})

    const countUserProfessional = sortedUserData.reduce((monthlyData, user) => {
      const monthYear = user.month_year
      if( !monthlyData.hasOwnProperty(monthYear) ){
        monthlyData[monthYear] = 0;
      }
      
      if(user.role == "PROFESSIONAL"){
        monthlyData[monthYear] = monthlyData[monthYear] + 1        
      }
      return monthlyData
    }, {})

    const countByUserType = [["Month", "Unkown", "Renovators", "Suppliers", "Professionals"]]

    for (let index = 1; index < Object.keys(countUserRenovator).length; index++) {
      countUserUnkown[Object.keys(countUserUnkown)[index]] += countUserUnkown[Object.keys(countUserUnkown)[index-1]]
      
      countUserRenovator[Object.keys(countUserRenovator)[index]] += countUserRenovator[Object.keys(countUserRenovator)[index-1]]

      countUserProfessional[Object.keys(countUserProfessional)[index]] += countUserProfessional[Object.keys(countUserProfessional)[index-1]]

      countUserSupplier[Object.keys(countUserSupplier)[index]] += countUserSupplier[Object.keys(countUserSupplier)[index-1]]
    }

    Object.keys(countUserProfessional).forEach(monthYear => {
      countByUserType.push([monthYear, countUserUnkown[monthYear], countUserRenovator[monthYear], countUserSupplier[monthYear], countUserProfessional[monthYear]])
    })

    countByUserTypeOptions.dataToDisplay = countByUserType

    return countByUserTypeOptions
}

const getProfessionalsSegmentationData = (usersData) => {
    
    const countsOfProfessionalsOptions = {
        customTitle: 'Various professionals on platform',
        curveType: "function",
        graphType: 'Table',
        legend: { position: "bottom" },
        pageSize: 10,
    };

  const countMap = {};
  const countByUserType = [["User type", "Number of users"]]
  let typesOfProfessionals = []

  let counts = usersData.reduce((x, y) => {
    (x[y.role] = x[y.role] || []).push(y)
    return x
  }, {})

  Object.keys(counts).forEach(key => {
    if(key != "null"){
      if(key == 'PROFESSIONAL'){
        countByUserType.push([key + "S", counts[key].length])
        typesOfProfessionals.push(counts[key].map(prof => {
            return JSON.parse(prof.user_tags )
        }))
      }
    }
    
  })

  typesOfProfessionals[0].forEach(innerArray => {
    if (innerArray) {
        innerArray.forEach(role => {
            const upperRole = role.toUpperCase();
            countMap[upperRole] = (countMap[upperRole] || 0) + 1;
        });
    }
  });

  delete countMap['OTHER']
  const sortedCountMap = Object.entries(countMap)
  .sort(([,a],[,b]) => b-a)
  .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

  const countRoles = Object.entries(sortedCountMap).map(([key, value]) => [key, value]);
  
  let countsForVis = [['Professional business', 'Number of users']]
  countsForVis = countsForVis.concat(countRoles)

  countsOfProfessionalsOptions.dataToDisplay = countsForVis

  return countsOfProfessionalsOptions
}

const getB2BUserInfo = (usersData) => {
    
  const b2bUserTableOptions = {
      customTitle: 'Registered suppliers and professionals',
      curveType: "function",
      graphType: 'Table',
      legend: { position: "bottom" },
      pageSize: 20,
      allowHtml: true
  };

  let b2bUserDetails = [["Name", "Email", "Business type", "Business tags", "Registered on"]]

  const b2bUsers = usersData.filter(userData => {
    return userData.role == "PROFESSIONAL" || userData.role == "SUPPLIER"
  })
  
  const sortedUserData = b2bUsers.sort((u1, u2) => {
    return ( Date.parse(u1.created_at) < Date.parse(u2.created_at) )
  })
  
  sortedUserData.forEach(userData => {
    const registrationDateString = new Date(userData.created_at)
    const data = [userData.name, "<a href='mailto:" + userData.email + "'>" + userData.email + "</a>", userData.role, userData.user_tags, registrationDateString]
    b2bUserDetails.push(data)
  })

  b2bUserTableOptions.dataToDisplay = b2bUserDetails
  return b2bUserTableOptions

}